import React from 'react';
import Layout from '../../components/layout';
import { Link, navigate } from "gatsby";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import './review.scss';

export default function Reviews() {
  return (
    <>
      <Layout
        title='Tab Manager Reviews'
        description='Best Tab Managers for Chrome | Review'
        keywords={[
          'tab manager reviews',
          'tab manager',
          'best tab manager for chrome'
        ]}
      >
        <div className='root' style={{ paddingTop: '3rem' }}>
          <Container maxWidth='md'>
            <div style={{ marginBottom: 30 }}>
              <h1>Reviews</h1>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container justifyContent="center" spacing={2}>
                  <Card onClick={() => navigate('best-cluster-tab-manager-alternative')}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: '240px'}}
                        image="/images/productivity-1.svg"
                        title="tabmanager.io promo"
                      />
                      <CardContent>
                        <h2 style={{ marginTop: 12 }}>
                          {`Best Cluster Tab Manager alternative in ${new Date().getFullYear()}`}
                        </h2>
                        <p>
                          Looking for the best Cluster Tab Manager alternative?
                          Here we do a full feature comparison between all the current features that Cluster and Tabmanager.io supports.
                        </p>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button size="small" color="primary">
                        <Link to='best-cluster-tab-manager-alternative'>
                          Learn More
                        </Link>
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Layout>
    </>
  );
}
